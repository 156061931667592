<template>
  <section id="about" class="about mt-5">
    <div class="container">
      <div class="section-title">
        <h2 class="about-us-title">{{ $t("about_us.title") }}</h2>
        <div class="about-us__block">
          <div class="about-us__block-imgs">
            <img src="../assets/certificateKg.png" alt="" />
            <img src="../assets/certificateRu.png" alt="" />
          </div>
          <p>{{ $t("about_us.description") }}</p>
        </div>
      </div>

      <div class="container-jk">
        <div class="logo-block">
          <!-- <img src="https://yntymak.kg/wp-content/uploads/2021/02/kni.jpg" class="img-fluid about-us-image" alt=""> -->
          <img
            src="../assets/logo-ok.png"
            class="logo img-fluid about-us-image"
          />
          <div class="btn-logo_container">
            <a
              class="btn btn-primary px-4"
              target="_blank"
              href="https://drive.google.com/file/d/1_0UTxMrle0ulqjb6E-QeP_LdcNpCVJy1/view?usp=sharing"
            >
              Устав
            </a>
            <i class="fas fa-file-pdf" style="color: red"></i>
          </div>
        </div>
        <div class="title-block">
          <h3>Миссия</h3>
          <p class="fst-italic">
            {{ $t("about_us.mission_description") }}
          </p>
          <p>
            {{ $t("about_us.subdescription") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.section-title {
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
  border-bottom: 1px solid rgb(179, 179, 179);
}
.about-us__block-imgs {
  display: flex;
  flex-direction: row;
  gap: 0 10%;
}
.about-us__block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 5%;
  margin: 20px 0;
  text-align: justify;
}
.about-us__block img {
  width: 40%;
}
.about-us-title {
  position: relative;
  top: 20px;
  font-weight: bold;
  letter-spacing: 5px;
}
.about-us-image {
  height: 380px;
}
.about-us .container-expand {
  display: flex;
  justify-content: center;
}

.about-us-text {
  padding: 80px 0;
  max-width: 1040px;
}
.about-us-text p {
  margin: 0;
}
.about-us-text .about-us-par {
  margin-bottom: 40px;
}
h2 {
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 55px;
  font-family: "Myriad Pro Bold", sans-serif;
  font-size: 48px;
}
p {
  font-size: 18px;
}
.top {
  margin-top: 20px !important;
}
.container-jk {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.logo-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-block {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: justify;
}
.btn-logo_container {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 5%;
}

.fa-file-pdf {
  font-size: 170%;
  transition: 400ms;
}
@media (max-width: 1000px) {
  .container-jk {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo-block {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-block img {
    width: 70%;
    height: 70%;
  }
  .title-block {
    width: 100%;
  }
}

@media (max-width: 750px) {
  .about-us__block {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
  }
  .about-us__block-imgs {
    display: flex;
    justify-content: center;
  }
  .logo-block img {
    width: 60%;
    height: 60%;
  }
}
@media (min-width: 320px) and (max-width: 425px) {
  .about-us-title {
    position: relative;
    top: 20px;
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 5px;
  }
  .about-us-image {
    height: 280px;
  }
}
</style>
